export const dataCategory = [
    {
        id : 1,
        img : require('../../../assets/images/cat-1.jpg'),
        img_mobile : require('../../../assets/images/icon-1.png'),
        title : 'Зрение'
    },
    {
        id : 2,
        img : require('../../../assets/images/cat-2.jpg'),
        img_mobile : require('../../../assets/images/icon-2.png'),
        title : 'Слух'
    },
    {
        id : 3,
        img : require('../../../assets/images/cat-3.jpg'),
        img_mobile : require('../../../assets/images/icon-5.png'),
        title : 'Речь'
    },
    {
        id : 4,
        img : require('../../../assets/images/cat-4.jpg'),
        img_mobile : require('../../../assets/images/icon-3.png'),
        title : 'Мелкую моторику'
    },
    {
        id : 5,
        img : require('../../../assets/images/cat-5.jpg'),
        img_mobile : require('../../../assets/images/icon-4.png'),
        title : 'Крупную моторику'
    },
    {
        id : 6,
        img : require('../../../assets/images/cat-6.jpg'),
        img_mobile : require('../../../assets/images/icon-7.png'),
        title : 'Обоняние'
    },
    {
        id : 7,
        img : require('../../../assets/images/cat-7.jpg'),
        img_mobile : require('../../../assets/images/icon-10.png'),
        title : 'Мышление'
    },
    {
        id : 8,
        img : require('../../../assets/images/cat-8.jpg'),
        img_mobile : require('../../../assets/images/icon-8.png'),
        title : 'Самоосознание'
    },
    {
        id : 9,
        img : require('../../../assets/images/cat-9.jpg'),
        img_mobile : require('../../../assets/images/icon-11.png'),
        title : 'Эмоции'
    },
    {
        id : 10,
        img : require('../../../assets/images/cat-10.jpg'),
        img_mobile : require('../../../assets/images/icon-9.png'),
        title : 'Предметные действия'
    },
    {
        id : 11,
        img : require('../../../assets/images/cat-11.jpg'),
        img_mobile : require('../../../assets/images/icon-6.png'),
        title : 'Тактильную чувствительность'
    },
    {
        id : 12,
        img : require('../../../assets/images/cat-12.jpg'),
        img_mobile : require('../../../assets/images/icon-12.png'),
        title : 'Навыки чтения'
    },
    {
        id : 13,
        img : require('../../../assets/images/cat-13.jpg'),
        img_mobile : require('../../../assets/images/icon-13.png'),
        title : 'Память'
    },
    {
        id : 14,
        img : require('../../../assets/images/cat-14.jpg'),
        img_mobile : require('../../../assets/images/icon-14.png'),
        title : 'Творческие навыки'
    },
    {
        id : 15,
        img : require('../../../assets/images/cat-15.jpg'),
        img_mobile : require('../../../assets/images/icon-15.png'),
        title : 'Навыки самообслуживания'
    },
    
]

export const moreCategory = [
    {
        id : 16,
        img : require('../../../assets/images/cat-16.jpg'),
        img_mobile : require('../../../assets/images/icon-16.png'),
        title : 'Массажный курс'
    },
    {
        id : 17,
        img : require('../../../assets/images/cat-17.jpg'),
        img_mobile : require('../../../assets/images/icon-17.png'),
        title : 'Игры с водой'
    },
    {
        id : 18,
        img : require('../../../assets/images/cat-18.jpg'),
        img_mobile : require('../../../assets/images/icon-18.png'),
        title : 'Музыкальное развитие'
    },
]