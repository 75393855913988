import { REQUEST_FORM } from "../actions/action_types";

const initState = {
    name : '',
    phone : '',
    email : ''
}

export const request_reduser = (state = initState, action) => {
    switch (action.type) {
        case REQUEST_FORM:
            return{
                ...state,
                name : action.name,
                phone : action.phone,
                email : action.email
            }
        default: return state
    }
}