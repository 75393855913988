import React from 'react'
import './lectors_screen.scss'
import { dataLectors } from './data_lectors'
import { Element } from 'react-scroll'
import Slider from 'react-slick'

export const Lectors = () => {
  const itemLector = dataLectors.map((item, index) => {
    const skill = item.skills.map((s, idx) => (
      <li key={idx} className="lector__skill">
        {s}
      </li>
    ))
    return (
      <div className="lectors__col" key={index}>
        <div className="lector__card">
          <div className="lector__wrapImg">
            <picture>
              <source srcSet={item.img2000} type="image/jpf"/>
              <img srcSet={item.img} alt=""/>
            </picture>
          </div>
          <div className="lector__body">
            <div className="lector__name">
              <h3 className="lector__nameText">{item.name}</h3>
            </div>
            <ul className="lector__skillLIst">{skill}</ul>
          </div>
        </div>
      </div>
    )
  })
  const settings = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          autoplaySpeed: 2000
        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }
  return (
    <Element className="lectors" name="lectors">
      <div className="container">
        <h2 className="title-h2">
          <b className="title-h2__innertitle">Baby Course</b>- это полноценная
          программа раннего развития, созданная экспертами на основе передовых
          методик, исследований и трудов известных ученых.
        </h2>
        {/* <div className="lectors__content"></div> */}
        <Slider {...settings}>{itemLector}</Slider>
        <div className="lectors__box">
          <p className="title-h2__subTitle">
            Программа развития BabyCourse вдохновлена трудами ученых:
          </p>
          <p className="lectors__text">
            Dr.Thomas Verny, John Kelly, T.Berry Brazelton, Glenn Domann, David
            Chamberlain, Daniel N.Stern, Lisa Miller, Carolina Von Heidebrand,
            Maria Montessori, Loren Bredvey, Barbara Albers Hill, M.Rhonda
            Folio, Rebecca R. Fewell, Jean Piaget, Stanislav Grof, Nikolay
            Zaytsev, Vladimir Bazarniy, John Medina.
          </p>
        </div>
      </div>
    </Element>
  )
}
