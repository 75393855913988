import React, { lazy, Suspense } from 'react'
import './App.scss'
import { Route, BrowserRouter } from 'react-router-dom'
import { state } from './store/state'
import { FirstScreen } from './modules/landing/first_screen/Fitst_screen'
import store from './redux/store'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'

const Privacy = lazy(() => import('./modules/landing/pages/Privacy'))
export const Context = React.createContext()

const App = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <CookiesProvider>
        <Context.Provider value={state}>
          <Provider store={store}>
            <div className="App">
              <Route exact path="/" component={FirstScreen}/>
              <Suspense fallback={<div>Загрузка...</div>}>
                <Route path="/privacy" component={Privacy}/>
              </Suspense>
              {/* <Route path="/download" component={DownloadApp} /> */}
            </div>
          </Provider>
        </Context.Provider>
      </CookiesProvider>
    </BrowserRouter>
  )
}

export default App
