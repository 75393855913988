export const state = {
    token: '',
    name: '',
    cel: '',
    description: '',
    video: '',
    numDays: '',
    priority: '',
    category: null,
    benefitsItem: '',
    benefitsLists: [],
    attempts: {
        min: 0,
        max: 24,
    },
    leadTime: {
        min: 0,
        max: 24,
    },

    isAuth: false,
    viewFile: '',
    resData: [],
    messageForAdmin: '',
    currentElementId: '',
    redirect: false,
    currentCompanent: '',

    categoryName: '',
    descriptionCategory: '',
    categoryImage: '',

    categoryList: [],
    categorySelected: '',

    benefits: [],
    benefitsSelected: [],
    benefitsSelected2: [],

    benefitsName: '',
    benefitsDescript: '',
    benefitsPreview: '',
    benefitsLink: '',
    benefitsMonth: '',


    melodyList: [],
    melodyName: '',
    audio: '',
    aiduoPlayer: '',
    typeMelody: '',
    numMonth: '',

    viewText: false,
    textName: '',

    typeText: '',
    text: '',
    numMonthViewText: '',
    textList: [],

    fileName: '',
    numMonthViewFile: '',
    FileList: [],

    lectureList: [],
    lectureName: '',
    lectureVideo: '',
    lectureMonth: '',
    lectureOrder: '',

    NormList: [],
    NormName: '',
    NormPeriod: [],
    NormMonth: '',
    normCategory: ''
}
