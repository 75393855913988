import React from 'react'
import './header_screen.scss'
import { Logo } from './logo/Logo'
import { Navigation } from './navigation/Navigation'

export const Header = () => {

    return(
        <div className="header">
            <div className="container header__inner">
                <Logo />
                <Navigation />
            </div>
        </div>
    )
}