export const promo_video = {
  uri: 'https://youtu.be/ZPTAYg9eHOs'
}
export const dataSlider = [
  {
    img: require('../../../assets/images/card-3.png'),
    img2000: require('../../../assets/images/card-1.jpf'),
    text: 'Занимайтесь с малышом по наглядным видео урокам',
    textAfter: 'Комплекс из более 250  наглядных видео упражнений'
  },
  {
    img: require('../../../assets/images/card-5.png'),
    img2000: require('../../../assets/images/card-2.jpf'),
    text: 'Экономьте  своё время с удобным расписанием',
    textAfter: 'Ежедневное расписание занятий на 20-30 минут в день'
  },
  {
    img: require('../../../assets/images/card-2.png'),
    img2000: require('../../../assets/images/card-3.jpf'),
    text: 'Следите за своевременным развитием  своего ребёнка',
    textAfter: 'Персональный дневник контроля своевременного развития малыша'
  },
  {
    img: require('../../../assets/images/card-1.png'),
    img2000: require('../../../assets/images/card-4.jpf'),
    text: 'Обучайтесь у эксперта раннего развития, остеопата и массажиста',
    textAfter: 'Курс уроков от эксперта раннего развития, остеопата и массажиста'
  },
  {
    img: require('../../../assets/images/card-4.png'),
    img2000: require('../../../assets/images/card-5.jpf'),
    text: 'Заранее подготавливайте необходимые игры и пособия',
    textAfter: 'Список всех необходимых пособий и игрушек'
  },
  {
    img: require('../../../assets/images/card-6.png'),
    img2000: require('../../../assets/images/card-6.jpf'),
    text: 'Пойте ребёнку колыбельные, читайте потешки и пестушки',
    textAfter: 'Библиотека пестушек, потешек, песенок и колыбельных'
  }
]
