import React from 'react'
import { Field } from 'formik'

const CustomInput = ({name, type, placeholder}) => {
  return (
    <Field
      name={name}
    >
      {
        (
          {
            field, // { name, value, onChange, onBlur }
            form: {touched, errors},
            meta,
          }
        ) => (
          <>
            <input
              type={type}
              min={0}
              className={
                meta.touched && meta.error
                  ? 'form-request__input form-request__input--error'
                  : 'form-request__input'
              }
              placeholder={placeholder}
              {...field}
            />
          </>
        )}
    </Field>
  )
}

export default CustomInput
