export const firstSlides = [
  {
    img: require('../../../assets/images/slider-first/BabyCourse_Desktop_Main.jpg'),
    img_mobile: require('../../../assets/images/slider-first/BabyCourse_Mobile_9.jpg'),
    img2000: require('../../../assets/images/slider-first/BabyCourse_Desktop_Main.jpf'),
    img_mobile2000: require('../../../assets/images/slider-first/BabyCourse_Mobile_9.jpf')
  },
  {
    img: require('../../../assets/images/slider-first/BabyCourse_Desktop_Main_8.jpg'),
    img_mobile: require('../../../assets/images/slider-first/BabyCourse_Mobile_8.jpg'),
    img2000: require('../../../assets/images/slider-first/BabyCourse_Desktop_Main_8.jpf'),
    img_mobile2000: require('../../../assets/images/slider-first/BabyCourse_Mobile_8.jpf'),
  },
  {
    img: require('../../../assets/images/slider-first/BabyCourse_Desktop_Main_2.jpg'),
    img_mobile: require('../../../assets/images/slider-first/BabyCourse_Mobile_10.jpg'),
    img2000: require('../../../assets/images/slider-first/BabyCourse_Desktop_Main_2.jpf'),
    img_mobile2000: require('../../../assets/images/slider-first/BabyCourse_Mobile_10.jpf'),
  }
]

export const bottomSlides = [
  {
    img: require('../../../assets/images/slider-first/BabyCourse_Desktop_Main_12.jpg'),
    img_mobile: require('../../../assets/images/slider-first/BabyCourse_Mobile_7.jpg'),
    img2000: require('../../../assets/images/slider-first/BabyCourse_Desktop_Main_12.jpf'),
    img_mobile2000: require('../../../assets/images/slider-first/BabyCourse_Mobile_7.jpf'),
  },
  {
    img: require('../../../assets/images/slider-first/BabyCourse_Desktop_Main_3.jpg'),
    img_mobile: require('../../../assets/images/slider-first/BabyCourse_Mobile_11.jpg'),
    img2000: require('../../../assets/images/slider-first/BabyCourse_Desktop_Main_3.jpf'),
    img_mobile2000: require('../../../assets/images/slider-first/BabyCourse_Mobile_11.jpf'),
  },
  {
    img: require('../../../assets/images/slider-first/BabyCourse_Desktop_Main_4.jpg'),
    img_mobile: require('../../../assets/images/slider-first/BabyCourse_Mobile_3.jpg'),
    img2000: require('../../../assets/images/slider-first/BabyCourse_Desktop_Main_4.jpf'),
    img_mobile2000: require('../../../assets/images/slider-first/BabyCourse_Mobile_3.jpf'),
  }
]
