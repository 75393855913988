import React from 'react'
import './categorys.scss'
import { dataCategory, moreCategory } from './dataCategory'
import { Element } from 'react-scroll'


export const Caterory = () => {
    
    const itemCategory = dataCategory.map(item => {
        return(
            <div className="category__col" key={item.id}>
                <div className="category__item" >
                    <div className="category__wrapIcon">
                        <img className="category__icon" src={item.img} alt="icon"/>
                        <img className="category__icon-mobile" src={item.img_mobile} alt="icon"/>
                    </div>
                    <p className="category__text">{item.title}</p>
                </div>
            </div>
        )
    })
    const itemMoreCategory = moreCategory.map(item => {
        return(
            <div className="category__col" key={item.id}>
                <div className="category__item" >
                    <div className="category__wrapIcon">
                        <img className="category__icon" src={item.img} alt="icon"/>
                        <img className="category__icon-mobile" src={item.img_mobile} alt="icon"/>
                    </div>
                    <p className="category__text">{item.title}</p>
                </div>
            </div>
        )
    })
    return(
        <Element className="category" name="category">
            <div className="container">
            <h2 className="title-h2">
                <b className="title-h2__innertitle">Baby Course </b>
                поможет Вашему малышу развивать:
            </h2>
            <div className="category__content">
                {itemCategory}
                <p className="title-h2__subTitle">Программа развития также включает:</p>
                {itemMoreCategory}
            </div>
            </div>
            
        </Element>
    )
}