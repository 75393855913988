import React, { useState } from 'react'
import './navigation.scss'
import {HiMenu} from 'react-icons/hi'
import { Link } from 'react-scroll'

export const Navigation = () => {
    const [isMobile, setMobile ] = useState(false)

    return(
        <div className="navigation">
            <div className="navigation__menu" onClick={() => setMobile(!isMobile)}>
                <HiMenu size={42} color="#fff" />
            </div>
            <ul className={ 
                !isMobile
                ? `${'navigation__list'}`
                : `${'navigation__list navigation__list-show'}`
             }>
                <li className="navigation__item">
                    <Link activeClass="active" className="navigation__link" to="about-app" spy={true} smooth={true} duration={500} onClick={() => setMobile(false)}>О приложении</Link>
                </li>
                <li className="navigation__item">
                    <Link activeClass="active" className="navigation__link" to="category" spy={true} smooth={true} duration={500} onClick={() => setMobile(false)}>Развитие</Link>
                </li>
                <li className="navigation__item">
                    <Link activeClass="active" className="navigation__link" to="video" spy={true} smooth={true} duration={500} onClick={() => setMobile(false)}>Упражнения</Link>
                </li>
                <li className="navigation__item">
                    <Link activeClass="active" className="navigation__link" to="lectors" spy={true} smooth={true} duration={500} onClick={() => setMobile(false)}>Эксперты</Link>
                </li>
            </ul>
        </div>
    )
}