export const dataButton = [
  { id: 1, text: '0 - 2 мес' },
  { id: 2, text: '3 - 4 мес' },
  { id: 3, text: '5 - 6 мес' },
  { id: 4, text: '7 - 8 мес' },
  { id: 5, text: '9 - 10 мес' },
  { id: 6, text: '11 - 12 мес' }
]

const data = {
  zrenie: {
    color: '#0e63bc',
    icon: require('../../../assets/images/icon-1.png')
  },
  rech: {
    color: '#b77dbd',
    icon: require('../../../assets/images/icon-5.png')
  },
  mMotirica: {
    color: '#c5e642',
    icon: require('../../../assets/images/icon-3.png')
  },
  kMotirica: {
    color: '#8f93fa',
    icon: require('../../../assets/images/icon-4.png')
  },
  taktil: {
    color: '#7b4d92',
    icon: require('../../../assets/images/icon-6.png')
  },
  mishlenie: {
    color: '#1aae85',
    icon: require('../../../assets/images/icon-10.png')
  },
  voda: {
    color: '#8f93fa',
    icon: require('../../../assets/images/icon-4.png')
  },
  slush: {
    color: '#3fdbf4',
    icon: require('../../../assets/images/icon-2.png')
  },
  deystviy: {
    color: '#9eecd7',
    icon: require('../../../assets/images/icon-9.png')
  },
  samo: {
    color: '#7db8ec',
    icon: require('../../../assets/images/icon-15.png')
  },
}
export const dataSlider = [
  [
    {
      id: 1,
      url: 'https://youtu.be/suzEEpTO1_Y',
      title: 'Погремушка ',
      categories: 'Зрение',
      color: data.zrenie.color,
      icon: data.zrenie.icon,
      description: 'В этом упражнении при помощи погремушки мы учим ребёнка следить за предметом. Перемещайте игрушку вправо-влево, вверх-вниз, при этом постоянно возвращайте её к глазам ребёнка. Со временем пробуйте постепенно увеличивать скорость перемещения погремушки.'
    }, {
    id: 2,
    url: 'https://youtu.be/cJSdUP679iU',
    title: 'Звучащая игрушка',
    categories: 'Зрение',
    color: data.zrenie.color,
    icon: data.zrenie.icon,
    description: 'Привлекайте внимание ребёнка к музыкальной игрушке. Важно, чтобы ребёнок сосредоточился на игрушке на 30 - 40 секунд и более. Каждые 4-5 занятий меняйте игрушку, чтобы ребёнку было интересно.'
  }, {
    id: 3,
    url: 'https://youtu.be/EpJnbMunq50',
    title: 'Где малыш? ',
    categories: 'Речь',
    color: data.rech.color,
    icon: data.rech.icon,
    description: 'Положите ребёнка на спину или на свои колени и прочитайте полешку. Разговаривая с малышом, щекочите его, гладьте, тормошите и улыбайтесь.'
  }, {
    id: 4,
    url: 'https://youtu.be/4ej5GkHD54o',
    title: 'Пальчики в кулачки',
    categories: 'Мелкая моторика',
    color: data.mMotirica.color,
    icon: data.mMotirica.icon,
    description: 'Каждый раз, когда вы подходите к малышу, вкладывайте свои большие пальцы в кулачки ребенка. Чем чаще вы будете это делать, тем лучше. И научите всех близких делать то же самое, когда они общаются с малышом. Данное упражнение способствует развитию речевых зон мозга и мелкой моторики.'
  }, {
    id: 5,
    url: 'https://youtu.be/UnKZZvVR_Ow ',
    title: 'Мобильчик',
    categories: 'Мелкая моторика',
    color: data.mMotirica.color,
    icon: data.mMotirica.icon,
    description: 'Повесьте перед ребёнком мини-мобиль. Пусть малыш рассматривает игрушку и задевает её ручками на протяжении 3-5 минут. Находитесь рядом с ребёнком всё время, пока он будет увлечен этим процессом.'
  },
  ],
  [
    {
      id: 6,
      url: 'https://youtu.be/uvLSGSiQxAU',
      title: 'Деревянные фигуры ',
      categories: 'Мелкая моторика',
      color: data.mMotirica.color,
      icon: data.mMotirica.icon,
      description: 'Положите малыша на спину и сядьте рядом. Предлагайте ему по очереди деревянные бруски разной формы и цвета в течение 1-2 минут. В этом упражнении мы учим ребёнка действиям с предметами разной формы. При первоначальном знакомстве с фигурками, малыш их будет хватать как попало, но в дальнейшем он будет заранее готовить руку для более удобного захвата. '
    }, {
    id: 7,
    url: 'https://youtu.be/pCUKXE6z2Qo',
    title: 'Игра ножками',
    categories: 'Крупная моторика',
    color: data.kMotirica.color,
    icon: data.kMotirica.icon,
    description: 'Положите ребёнка на спину и держите подвесную игрушку возле его ножек. Немного понаблюдайте за малышом, а затем, если он сам не догадался, что от него требуется, аккуратно возьмите его ножку и толкните ей игрушку. Дайте малышу поиграть ножками 1-2 минуты. '
  }, {
    id: 8,
    url: 'https://youtu.be/rNY0HF9V1_U',
    title: 'Раз морозною зимой',
    categories: 'Речь',
    color: data.rech.color,
    icon: data.rech.icon,
    description: 'Спойте ребёнку песенку.'
  }, {
    id: 9,
    url: 'https://youtu.be/2QW4Z0jycoc',
    title: 'Заводная игрушка',
    categories: 'Мышление',
    color: data.mishlenie.color,
    icon: data.mishlenie.icon,
    description: 'Положите ребёнка на коврик на живот. Заведите игрушку и дайте малышу понаблюдать за ней 1-2 минуты. Обращайте свое внимание интересно ли ему, следит ли он за движением игрушки, побуждает ли она его к ползанию и сколько времени малыш за ней наблюдает. Данное упражнение способствует развитию мышления ребёнка.'
  }, {
    id: 10,
    url: 'https://youtu.be/UWpI00vym7o',
    title: 'Мячик в воде',
    categories: 'В воде',
    color: data.voda.color,
    icon: data.voda.icon,
    description: 'Когда малыш купается, положите в воду мячик. Побуждайте ребенка к тому, чтобы он ловил руками мячик. Помогайте ему, если у него сначала не получается и хвалите его за любые попытки. Данное упражнение способствует развитию крупной и мелкой моторики ребёнка.'
  },
  ],
  [
    {
      id: 11,
      url: 'https://youtu.be/1-WNdXoy0RQ',
      title: 'Окружающий мир',
      categories: 'Речь',
      color: data.rech.color,
      icon: data.rech.icon,
      description: 'Привлеките внимание ребёнка к карточкам и показывайте по одной. Если малыш спокойно и с интересом рассматривает картинки, рассказывайте что на них изображено и называйте один факт или более. Если же это не так, то показывайте карточки быстро по 7-10 штук за занятие. Мозг малыша всё равно запомнит информацию. '
    }, {
    id: 12,
    url: 'https://youtu.be/fkMaDUU-q8A',
    title: 'Тук-тук-тук',
    categories: 'Мелкая моторика',
    color: data.mMotirica.color,
    icon: data.mMotirica.icon,
    description: 'Покажите малышу кубик и скажите, какого он цвета. Пусть он возьмет его в одну руку. Затем покажите второй кубик, скажите какого он цвета и дайте ребенку в другую руку. Понаблюдайте некоторое время, что он будет делать. Затем аккуратно возьмите ручки ребенка в свои и покажите, как можно стучать предметом о предмет. Выполняйте упражнение 1-2 минуты.'
  }, {
    id: 13,
    url: 'https://youtu.be/pjmwuZrhagU',
    title: 'Книжки',
    categories: 'Речь',
    color: data.rech.color,
    icon: data.rech.icon,
    description: 'Читайте ребёнку короткие стишки и сказки и рассматривайте вместе картинки 3-5 минут. Читая книжки малышу, вы не только способствуете его речевому развитию, но и прививаете любовь к книгам.'
  }, {
    id: 14,
    url: 'https://youtu.be/b13o1lH3jI8 ',
    title: 'Игра с неваляшкой ',
    categories: 'Крупная моторика',
    color: data.kMotirica.color,
    icon: data.kMotirica.icon,
    description: 'В данном упражнении мы мотивируем ребёнка подползти к вам при помощи неваляшки. Сядьте напротив малыша на расстоянии около 1 метра и поставьте перед собой игрушку. Смотрите на малыша и двигайте игрушкой, привлекая его к себе. Когда он подползёт к неваляшке, похвалите его и дайте с ней поиграть.'
  },
  ],
  [
    {
      id: 15,
      url: 'https://youtu.be/E_PdISzSJTM ',
      title: 'Липучки',
      categories: 'Мелкая моторика',
      color: data.mMotirica.color,
      icon: data.mMotirica.icon,
      description: 'Возьмите дартс на липучках и покажите ребёнку как можно отлепить мячик и прилепить его обратно. На этом этапе мы учим ребёнка только тому как отлеплять мячик, но как только он научится это делать, учите его отлеплять и прилеплять.'
    }, {
    id: 16,
    url: 'https://youtu.be/wAZzod93T7A',
    title: 'Качайся и доставай',
    categories: 'Мелкая моторика',
    color: data.mMotirica.color,
    icon: data.mMotirica.icon,
    description: 'Положите ребёнка животом на фитбол и покатайте его вперёд-назад, обязательно придерживая. Покажите ему игрушку и положите ее на пол так, чтобы малыш мог дотянуться до неё при наклонении фитбола вперёд.  Помогите, если он затрудняется. Похвалите малыша, когда у него получится и дайте поиграть с игрушкой.'
  }, {
    id: 17,
    url: 'https://youtu.be/JnIHkzjBWBA',
    title: 'Машинка',
    categories: 'Мышление',
    color: data.mishlenie.color,
    icon: data.mishlenie.icon,
    description: 'Предложите ребёнку подтянуть машинку за верёвочку. Если он затрудняется, продемонстрируйте как это можно сделать или подтяните его рукой машинку поближе. Побуждайте ребёнка самостоятельно подтягивать к себе игрушку. При помощи такой простой игры мы развиваем мышление малыша'
  }, {
    id: 18,
    url: 'https://youtu.be/KFju0QKJn9U ',
    title: 'Поймай мячик',
    categories: 'Мелкая моторика',
    color: data.mMotirica.color,
    icon: data.mMotirica.icon,
    description: 'Покажите малышу игрушку на резинке и начните медленно её раскачивать. Побуждайте ребенка поймать игрушку. Увеличивайте скорость раскачивания, если малыш хорошо справляется и уменьшайте, если он затрудняется. Радостно реагируйте и хвалите ребёнка, когда он ловит игрушку. '
  }, {
    id: 19,
    url: 'https://youtu.be/qBFH6_3OiPw',
    title: 'Узелки, верёвочки',
    categories: 'Мелкая моторика',
    color: data.mMotirica.color,
    icon: data.mMotirica.icon,
    description: 'Продемонстрируйте ребёнку, как можно за узелок вытащить из коробочки веревочку, а затем предложите ему сделать это самому. Помогайте ему, если он затрудняется и радостно реагируйте и хвалите, когда у него получается. Таким образом, в этом упражнении мы развиваем как мелкую моторику малыша, так и мышление.'
  },
  ],
  [
    {
      id: 20,
      url: 'https://youtu.be/9U5s9c80RRo',
      title: 'БиБаБо',
      categories: 'Речь',
      color: data.rech.color,
      icon: data.rech.icon,
      description: 'Возьмите куклы БиБаБо или любые другие игрушки и поиграйте ими с ребёнком. Спрячьтесь полностью или частично за стулом, спинкой дивана или ширмой и покажите малышу небольшой спектакль, сценку или просто повзаимодействуйте с ребёнком.'
    }, {
    id: 21,
    url: 'https://youtu.be/qzg20ZbHxxM ',
    title: 'Вкладыши',
    categories: 'Мышление',
    color: data.mishlenie.color,
    icon: data.mishlenie.icon,
    description: 'Покажите ребёнку как доставать и вставлять обратно вкладыши из дощечки. Побуждайте его делать это самостоятельно, при этом называйте фигуры, которые он берёт. Если малыш затрудняется, возьмите его ручку в свою и помогите вставить вкладыш на место. '
  }, {
    id: 22,
    url: 'https://youtu.be/KIrvz118R9c ',
    title: 'Холодно и тепло',
    categories: 'Тактильная чувствительность',
    color: data.taktil.color,
    icon: data.taktil.icon,
    description: 'Возьмите зип-пакет с тёплой водой и бусинками, а также тарелочку с замороженной в ней водой. Покажите малышу пакет с водой и дайте ему его потрогать и скажите: теплый! Затем покажите малышу ледяную тарелочку, скажите: холодная! Дайте возможность ребенку изучить предметы. Когда он трогает тарелочку, повторяйте: холодно, когда пакет - повторяйте: тепло. '
  }, {
    id: 23,
    url: 'https://youtu.be/yIBEI86IL7M',
    title: 'Струна',
    categories: 'Слух',
    color: data.slush.color,
    icon: data.slush.icon,
    description: 'В этом упражнении мы учим ребёнка извлекать звук из натянутого шнура. Когда малыш дёргает за него, воспроизводите этот звук голосом, чтобы ребёнок на нём сосредотачивался и прислушивался. '
  }, {
    id: 24,
    url: 'https://youtu.be/R_pmVYvCQ78',
    title: 'Пинай мячик',
    categories: 'Крупная моторика',
    color: data.kMotirica.color,
    icon: data.kMotirica.icon,
    description: 'Подведите ребёнка к мячику и скажите: «Посмотри какой мячик! Давай его пинать!». Показывайте как это делать и побуждайте малыша повторять за вами. Разговаривайте с ним, подбадривайте и хвалите.\n' +
      'Если у вас ребенок еще не очень хорошо ходит, то возьмите его под грудью, приподнимите и его ножками пинайте мячик. Это веселит и забавляет малышей.'
  },
  ],
  [
    {
      id: 25,
      url: 'https://youtu.be/Y2Qicz3lDr4 ',
      title: 'Рыбалка',
      categories: 'Мелкая моторика',
      color: data.mMotirica.color,
      icon: data.mMotirica.icon,
      description: 'Бросьте в емкость с водой шарики. Будет лучше если воды будет немного, чтобы ребенок не мог достать шарики рукой. Покажите малышу, как можно доставать шарики с помощью ситечка. Затем вложите ручку ситечка в руку ребенка и побуждайте его вылавливать шарики, при необходимости помогая ему.'
    }, {
    id: 26,
    url: 'https://youtu.be/eKh0UvRkRXQ',
    title: 'Скрепки',
    categories: 'Мелкая моторика',
    color: data.mMotirica.color,
    icon: data.mMotirica.icon,
    description: 'Заранее разместите на стене плёнку для оклеивания учебников и прилепите к ней разноцветные скрепки. Возьмите коробочку и подзовите к пособию ребёнка. Побуждайте его отлеплять скрепки и складывать их в коробочку, при необходимости помогая малышу и направляя его. '
  }, {
    id: 27,
    url: 'https://youtu.be/3hN6abGilBg',
    title: 'Бабочки',
    categories: 'Речь',
    color: data.rech.color,
    icon: data.rech.icon,
    description: 'Заранее вырежьте из бумаги 5-8 бабочек. Положите перед ребенком на стол одну бабочку и покажите, как можно сдуть ее с поверхности. Проделайте это действие несколько раз, рассказывая малышу как вы это делаете и побуждая его повторять за вами. После того как поиграли с одной бабочкой, положите на стол ещё несколько и продолжите игру. '
  }, {
    id: 28,
    url: 'https://youtu.be/Szgo1bjeErI',
    title: 'Сними шапку',
    categories: 'Самообслуживание',
    color: data.samo.color,
    icon: data.samo.icon,
    description: 'Наденьте на ребёнка шапку. Затем возьмите его ручку, поднесите к шапке и снимите шапку его рукой. Снова наденьте шапку и дайте возможность ребенку действовать самостоятельно. Если малыш затрудняется, повторите свои действия.'
  }, {
    id: 29,
    url: 'https://youtu.be/FURAmIdK4Y0',
    title: 'Бах',
    categories: 'Речь',
    color: data.rech.color,
    icon: data.rech.icon,
    description: 'Дайте ребёнку в ручку кубик и скажите: «Кидай». Продемонстрируйте ему как это делаете вы. Когда предмет упадет и вы услышите звук от падения, озвучьте его, скажите: «Бах!». Продолжайте сбрасывать с высоты предметы и произносить «Бах!», побуждая малыша повторять за вами и действие и слово.'
  },
  ]
]
