import React, { useEffect, useRef, useState } from 'react'
import { AboutAppHome } from '../about-app/About_app_home'
import { Appeal } from '../appeal/Appeal_screen'
import { Caterory } from '../categorys/Categorys'
import { ExempleVideo } from '../exemple-video/ExempleVideo'
import { Footer } from '../footer/Footer_screen'
import { RequestForm } from '../form/RequestForm'
import { Header } from '../header/Header_screen'
import { Lectors } from '../lectors/Lectors_screen'
import { SliderFirstScreen } from '../slider_first/Slider_First_screen'
import { firstSlides, bottomSlides } from './dataSliders'
import Modal from 'react-modal'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useCookies } from 'react-cookie'
import './first_screen.scss'

const TEXT_BOTTOM_FORM = 'Оставьте заявку на установку мобильного приложения и получите БЕСПЛАТНО подписку на 14 дней.'
const TEXT_APPSTORE_FORM = 'Приложение BabyCourse в данный момент проходит финальное тестирование и будет доступно для загрузки в самое ближайшее время. Оставьте заявку и получите бесплатную подписку к приложению на 1 месяц. '
const TEXT_GOOGLE_PLAY_FORM = 'Приложение BabyCourse в данный момент проходит финальное тестирование и будет доступно для загрузки в самое ближайшее время. Оставьте заявку и получите бесплатную подписку к приложению на 1 месяц. '

export const FirstScreen = () => {
  const [cookies, setCookie] = useCookies(['name'])

  const [counter, setCounter] = useState(0)
  const [isOpen, setOpen] = useState(false)

  const initDataForm = {
    text: '',
    flag: null
  }

  const dataBottomForm = {
    text: TEXT_BOTTOM_FORM,
    flag: 'Домашняя страница'
  }
  const [dataForm, setDataForm] = useState(initDataForm)
  const btn_store = useRef()

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (counter < 2) {
        setCounter(counter + 1)
        clearTimeout(timeout)
      } else {
        setCounter(0)
        clearTimeout(timeout)
      }
    }, 3000)
  }, [counter])

  const hendlerModal = (e) => {
    if (e.target.name === 'app-store') {
      setDataForm({
        ...dataForm,
        text: TEXT_APPSTORE_FORM,
        flag: 'app_store'
      })
    } else if (e.target.name === 'gooogle-play') {
      setDataForm({
        ...dataForm,
        text: TEXT_GOOGLE_PLAY_FORM,
        flag: 'google_play'
      })
    }
    setOpen(true)
  }

  const handleSocket = () => {
    setCookie('name', 'Tect', { path: '/' })
  }

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        className="Modal"
        overlayClassName="Overlay"
      >
        <button className="Modal__close" onClick={() => setOpen(!isOpen)}>
          <AiOutlineCloseCircle size={42} color="#333"/>
        </button>
        <RequestForm
          data={dataForm}
        />
      </Modal>
      <Header/>
      <div className="first-screen">
        <div className="container">
          {counter === 0 ? (
            <h1 className="first-screen__title">
              В 1-Й ГОД ЖИЗНИ ПРОИСХОДИТ САМОЕ БЫСТРОЕ РАЗВИТИЕ КЛЕТОК МОЗГА. НЕ УПУСТИТЕ ЭТО ВРЕМЯ
            </h1>
          ) : null}
          {counter === 1 ? (
            <h1 className="first-screen__title">
              ВСЕГО 20 МИНУТ В ДЕНЬ И ВАШ МАЛЫШ ПОЛУЧИТ ПРЕИМУЩЕСТВА НА ВСЮ ЖИЗНЬ
            </h1>
          ) : null}
          {counter === 2 ? (
            <h1 className="first-screen__title">
              ПОМОГИТЕ ВАШЕМУ РЕБЁНКУ РАЗВИВАТЬ ПОТЕНЦИАЛ УЖЕ С ПЕРВЫХ ДНЕЙ ЖИЗНИ
            </h1>
          ) : null}

          <div className="store-btns">
            <div className="store-btns__box">
              <div className="store-btns__link">
                <img
                  ref={btn_store}
                  name="app-store"
                  className="store-btns__img"
                  src={require('../../../assets/images/app-store.png')}
                  alt="app store"
                  onClick={(e) => hendlerModal(e)}
                />
              </div>
              <div className="store-btns__link">
                <img
                  ref={btn_store}
                  name="gooogle-play"
                  className="store-btns__img"
                  src={require('../../../assets/images/google-play.png')}
                  alt="google play"
                  onClick={(e) => hendlerModal(e)}
                />
              </div>
            </div>
            <img
              className="first-screen__phone"
              src={require('../../../assets/images/phone-view.png')}
              alt="img"
            />
          </div>
        </div>
        <div className="first-screen__slider">
          {/* eslint-disable-next-line no-undef */}
          <SliderFirstScreen slides={firstSlides}/>
        </div>
      </div>
      <div className="container">
        <AboutAppHome/>
      </div>
      <Appeal/>
      <Caterory/>
      <ExempleVideo/>
      <Lectors/>
      <div className="first-screen">
        <div className="container">
          <h1 className="first-screen__title first-screen__title-color">
            Помогите Вашему малышу развивать свой потенциал уже с первых дней
            жизни
          </h1>

          <div className="store-btns">
            <div className="store-btns__box">
              <div className="store-btns__link">
                <img
                  ref={btn_store}
                  name="app-store"
                  className="store-btns__img"
                  src={require('../../../assets/images/app-store.png')}
                  alt="app store"
                  onClick={(e) => hendlerModal(e)}
                />
              </div>
              <div className="store-btns__link">
                <img
                  ref={btn_store}
                  name="gooogle_play"
                  className="store-btns__img"
                  src={require('../../../assets/images/google-play.png')}
                  alt="google play"
                  onClick={(e) => hendlerModal(e)}
                />
              </div>
            </div>
            <img
              className="first-screen__phone"
              src={require('../../../assets/images/phone-view.png')}
              alt="img"
            />
          </div>
        </div>
        <div className="first-screen__slider">
          <SliderFirstScreen slides={bottomSlides}/>
        </div>
      </div>
      <RequestForm data={dataBottomForm}/>
      {
        'name' in cookies
          ? null
          : <div className="cocket-block">
            <p className="cocket-block__text">Продолжая пользоваться нашим сайтом, вы соглашаетесь на использование нами
              ваших
              файлов cookie.
            </p>
            <span className="cocket-block__close" onClick={() => handleSocket()}></span>
          </div>
      }

      <Footer/>
    </>
  )
}
