import React, { useState } from 'react'
import { Form, Formik, Field } from 'formik'
import CustomInput from './CustomInput'
import * as Yup from 'yup'
import { NavLink } from 'react-router-dom'
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im'

const CustomForm = ({ handlerSubmit }) => {
  const [isChecked, setChecked] = useState(true)

  const formSchema = Yup.object().shape({
    name: Yup.string().required('Обязательно к заполнению'),
    phone: Yup.number().required('Обязательно к заполнению').positive().integer(),
    email: Yup.string().required('Обязательно к заполнению').email()
  })

  return (
    <Formik
      validationSchema={formSchema}
      initialValues={{
        name: '',
        phone: '',
        email: ''
      }}
      onSubmit={(values, actions) => (handlerSubmit(values), actions.resetForm())}
      render={({ errors, touched }) => (
        <Form>
          <CustomInput name="name" placeholder="Ваше имя" type="text"/>
          <CustomInput name="phone" placeholder="Ваше Телефон" type="tel"/>
          <CustomInput name="email" placeholder="Ваш email" type="email"/>
          <div className="policy">
            <button
              className="policy__checkbox"
              onClick={() => setChecked(!isChecked)}
            >
              {/* eslint-disable-next-line multiline-ternary */}
              {isChecked ? (
                <ImCheckboxChecked size={18} color="#ffffff"/>
              ) : (
                <ImCheckboxUnchecked size={18} color="#ffffff"/>
              )}
            </button>
            <NavLink className="policy__link" to="/privacy">
              Соглашаюсь с обработкой персональных данных и политикой
              конфиденциальности
            </NavLink>
          </div>
          <button type="submit" className="form-request__submit">Оставить заявку</button>
        </Form>
      )}
    >

    </Formik>
  )
}

export default CustomForm
