export const dataLectors = [
  {
    img: require('../../../assets/images/experts/expert-anna.png'),
    name: 'Автор программы и курса по раннему развитию Анна Ярославцева ',
    skills: [
      '- Педагог (УГПУ, магистратура);',
      '- Психолог (МИПУ, магистратура);',
      '- Психолог (СГПУ, аспирантура);',
      '- Основатель центра детского ',
      'развития при международном ',
      'культурном центре “Радуга”;',
      '- Воспитатель с 20-летним опытом;',
      '- Автор уникальной программы ',
      'развития детей до 3-х лет;',
      '- Сертифицированный специалист',
      'системы “дизайн человека”;',
      '- Мама троих детей.'
    ]
  }, {
    img: require('../../../assets/images/experts/expert-mariy.png'),
    name: 'Врач педиатр и автор курса по педиатрии Мария Посельская',
    skills: [
      '- Педиатр (РНИМУ им.Пирогова);',
      '- Педиатр (ДКБ им.Сперанского, ',
      'интернатура);',
      '- Педиатр (СУПКП, курсы пов. квал.);',
      '- Интегральная превентивная медицина',
      '(Институт PreventAge); ',
      '- Клиническая генетика (Uniprof ',
      'Academy);',
      '- Ароматерапевт (Centre of Excellence);',
      '- Траволечение (Herbal Academy);',
      '- Мама двоих детей.'
    ]
  }, {
    img: require('../../../assets/images/experts/expert-oksana.png'),
    name: 'Остеопат, педиатр, массажист и аллерголог Оксана Тепикина',
    skills: [
      '- Краниопостуролог (ученица и',
      'коллега Владимира Животова);',
      '- Педиатр (РНИМУ, магистратура);',
      '- Педиатр (ИДГКБ, интерантура);',
      '- Аллерголог-иммунолог (ФГАУ ',
      '“НЦЗД Минздрава России”, ',
      'ординатура);',
      '- Манцальный терапевт (РУДН);',
      '- Массажист (ИВД, курсы пов.квал.);',
      '- Консультант в области здоровья',
      '(Академия специалистов индустрии ',
      'здоровья);',
      '- Мама двоих детей.'
    ]
  }, {
    img: require('../../../assets/images/experts/expert-karina.png'),
    name: 'Клинический логопед с 30-ти летним опытом Карина Коноплева',
    skills: [
      '- Логопед с 30-летним опытом;',
      '- Логопед-дефлектолог (МГПИ ',
      'им.Ленина);',
      '- Гештальт терапевт (МГПИ им.Ленина);',
      '- Специалист лаборатории паталогии',
      'речи;',
      '- Логопед отделения реабилитации',
      'больных с поражением опорно-',
      'двигательного аппарата;',
      '- Логопед в российской детской ',
      'клинической больнице;',
      '- Мама троих детей.'
    ]
  }, {
    img: require('../../../assets/images/experts/expert-marina.png'),
    name: 'Массажист с 15-ти летним опытом Марина Новикова',
    skills: [
      '- Массажист (Самарский',
      'Государственный Медицинский',
      'Университет); ',
      '- Сертифицированный телесный',
      'терапевт (ИПиКП, г.Москва);',
      '- Массажист (школа Валерия ',
      'Красавина, курсы пов. квал.);',
      '- Медицинский сотрудник в',
      'медицинском колледже им.Ляпиной;',
      '- Мама.'
    ]
  }, {
    img: require('../../../assets/images/experts/expert-olga.png'),
    name: 'Педагог по музыкальному развитию с 25-ти летним опытом Ольга Соловьёва',
    skills: [
      '- Педагог по музыкальному развитию',
      'детей (Самарский Гос. Социально-',
      'Педагогический Универстите); ',
      '- Музыкальный педагог (Самарский',
      'социально-педагогический колледж);',
      '- Педагог по музыкальному развитию',
      'детей в детском саду “Филиппок”;',
      '- Педагог по музыкальному развитию',
      'детей в центре детского развития при',
      'культурном центре “Радуга”;',
      '- Мама.'
    ]
  }
]
