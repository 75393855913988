import React, { useRef, useState } from 'react'
import './footer_screen.scss'
import Modal from 'react-modal'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { RequestForm } from '../form/RequestForm'

const TEXT_APSTORE_FORM = 'Приложение BabyCourse в данный момент проходит финальное тестирование и будет доступно для загрузки в самое ближайшее время. Оставьте заявку и получите бесплатную подписку к приложению на 1 месяц. '
const TEXT_GOOGLE_PLAY_FORM = 'Приложение BabyCourse в данный момент проходит финальное тестирование и будет доступно для загрузки в самое ближайшее время. Оставьте заявку и получите бесплатную подписку к приложению на 1 месяц. '

export const Footer = () => {
  const [isOpen, setOpen] = useState(false)

  const initDataForm = {
    text: '',
    flag: null
  }

  const [dataForm, setDataForm] = useState(initDataForm)
  const btn_store = useRef()
  const hendlerModal = (e) => {
    if (e.target.name === 'app-store') {
      setDataForm({
        ...dataForm,
        text: TEXT_APSTORE_FORM,
        flag: 'app_store'
      })
    } else if (e.target.name === 'gooogle-play') {
      setDataForm({
        ...dataForm,
        text: TEXT_GOOGLE_PLAY_FORM,
        flag: 'google_play'
      })
    }
    setOpen(true)
  }

  return (
    <div className='footer'>
      <div className='container'>
        <div className='footer__inner'>
          <div className='sochial'>
            <a
              className='sochial__link'
              href='https://instagram.com/baby_course'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                className='sochial__img'
                src={require('../../../assets/images/Insta.png')}
                alt=''
              />
            </a>
            <a
              className='sochial__link'
              href='https://facebook.com/BabyCourse'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                className='sochial__img'
                src={require('../../../assets/images/FB.png')}
                alt=''
              />
            </a>
            <a
              className='sochial__link'
              href='https://www.youtube.com/channel/UCG_P3orE6zuwY-DWIki7z6g?view_as=subscriber'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                className='sochial__img'
                src={require('../../../assets/images/YT.png')}
                alt=''
              />
            </a>
            <a
              className='sochial__link'
              href='https://vk.com/babycourse'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                className='sochial__img'
                src={require('../../../assets/images/VK.png')}
                alt=''
              />
            </a>
            <a
              className='sochial__link'
              href='https://api.whatsapp.com/send?phone=79851592320&text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%2C%20%D1%8F%20%D0%BF%D0%BE%20%D0%BF%D0%BE%D0%B2%D0%BE%D0%B4%D1%83%20%D0%B4%D0%B5%D1%82%D1%81%D0%BA%D0%BE%D0%B3%D0%BE%20%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F%20...'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                className='sochial__img'
                src={require('../../../assets/images/whatsapp.png')}
                alt=''
              />
            </a>
            <a
              className='sochial__link'
              href='https://t.me/joinchat/U6zhO4Rq2_2hJXh5'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                className='sochial__img'
                src={require('../../../assets/images/telegram.png')}
                alt=''
              />
            </a>
          </div>
          <div className='contact'>
            <p className='contact__text'>
              Телефон:{' '}
              <a href='tel:+78005550903' className='contact__link'>
                {' '}
                8 800 555 09 03
              </a>
            </p>
            <p className='contact__text'>
              Почта:{' '}
              <a href='mailto:mail@babycourse.app' className='contact__link'>
                mail@babycourse.app
              </a>
            </p>
          </div>
          <div className='footer-store'>
            <div className='store-btns'>
              <div className='store-btns__box'>
                <div className='store-btns__link'>
                  <img
                    ref={btn_store}
                    name='app-store'
                    className='store-btns__img'
                    src={require('../../../assets/images/app-store-w.png')}
                    alt='app store'
                    onClick={(e) => hendlerModal(e)}
                  />
                </div>
                <div className='store-btns__link'>
                  <img
                    ref={btn_store}
                    name='gooogle-play'
                    className='store-btns__img'
                    src={require('../../../assets/images/google-play-w.png')}
                    alt='google play'
                    onClick={(e) => hendlerModal(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <p className='copy'>Все права защищены. BabyCourse ®. 2020.</p>
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        className='Modal'
        overlayClassName='Overlay'
      >
        <button className='Modal__close' onClick={() => setOpen(!isOpen)}>
          <AiOutlineCloseCircle size={32} color='#333' />
        </button>
        <RequestForm data={dataForm} />
      </Modal>
    </div>
  )
}
