import React from 'react'
import './slider_first.scss'
import Slider from 'react-slick'
import useResizeWindow from '../../../hooks/resizeWindow'

export const SliderFirstScreen = (props) => {
  const settings = {
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2900,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false
  }

  const width = useResizeWindow()

  const itemSlide = props.slides.map((item, index) => {
    return (
      <div className="slide-wrap" key={index}>
        {
          width < 641
            ? <img className="slide" srcSet={item.img_mobile} alt=""/>
            : <img className="slide" srcSet={item.img} alt=""/>
        }

      </div>
    )
  })
  return (
    <div className="slider-wrap">
      <Slider {...settings}>
        {itemSlide}
      </Slider>
    </div>
  )
}
