import React, { useRef, useState } from 'react'
import './appeal_screen.scss'
import Modal from "react-modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { RequestForm } from '../form/RequestForm';


const TEXT_APSTORE_FORM = "Приложение BabyCourse в данный момент проходит финальное тестирование и будет доступно для загрузки в самое ближайшее время. Оставьте заявку и получите бесплатную подписку к приложению на 1 месяц. ";
const TEXT_GOOGLE_PLAY_FORM = "Приложение BabyCourse в данный момент проходит финальное тестирование и будет доступно для загрузки в самое ближайшее время. Оставьте заявку и получите бесплатную подписку к приложению на 1 месяц. ";


export const Appeal = () => {

  const [isOpen, setOpen] = useState(false);

  const initDataForm = {
    text : '',
    flag : null
  }

  const [dataForm, setDataForm] = useState(initDataForm)
  const btn_store = useRef();
  const hendlerModal = (e) => {
    if(e.target.name === 'app-store'){
      setDataForm({
        ...dataForm, 
        text : TEXT_APSTORE_FORM,
        flag : 'app_store'
      })
    }
    else if(e.target.name === 'gooogle-play'){
      setDataForm({
        ...dataForm, 
        text : TEXT_GOOGLE_PLAY_FORM,
        flag : 'google_play'
      })
    }
    setOpen(true)
  };

    return (
      <div className="appeal">
        <div className="container">
          <div className="appeal__content">
            <h2 className="appeal__title">
              <b className="appeal__innertitle">70% </b>
              развития мозга происходит в возрасте до 1 года
            </h2>
            <div className="appeal__wrapimage">
              <img
              className="appeal__bigimage"
                src={require("../../../assets/images/child.png")}
                alt="icon"
              />
            </div>
            <p className="appeal__title" style={{textAlign : 'center'}}>Не упустите самое важное время
                                                для развития Вашего малыша</p>
            <p className="appeal__text"></p>
            <div className="store-btns">
                <div className="store-btns__box">
                  <div className="store-btns__link">
                    <img
                      ref={btn_store}
                      name="app-store"
                      className="store-btns__img"
                      src={require("../../../assets/images/app-store.png")}
                      alt="app store"
                      onClick={(e) => hendlerModal(e)}
                    />
                  </div>
                  <div className="store-btns__link">
                    <img
                      ref={btn_store}
                      name="gooogle-play"
                      className="store-btns__img"
                      src={require("../../../assets/images/google-play.png")}
                      alt="google play"
                      onClick={(e) => hendlerModal(e)}
                    />
                  </div>
                </div>
              </div>
          </div>
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={isOpen}
          className="Modal"
          overlayClassName="Overlay"
        >
          <button className="Modal__close" onClick={() => setOpen(!isOpen)}>
            <AiOutlineCloseCircle size={32} color="#333" />
          </button>
          <RequestForm data={dataForm} />
        </Modal>
      </div>
    );
}