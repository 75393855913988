/* eslint-disable no-unreachable */
import { useState } from "react"
import { useCallback } from "react"

export const useHttp = () => {
    const [ isLoader, setLoader ] = useState(false)
    const [ isError, setError ] = useState(null)

    const request = useCallback( async (url, method='GET', body=null, headers={}) => {
        setLoader(true)
        try {
            const response = await fetch(url, { method, body, headers })
           
            const data = await response

            if( !response.ok ){
                throw new Error(data.message || 'Что то пошло не так')
            }
            setLoader(false)

            return data
        } catch (error) {
            setLoader(false)
            setError(error.message)
            throw error
        }

    }, [] )

    const clearError = () => {
        setError(null)
    }
    
    return { isLoader, request, isError, clearError }
}