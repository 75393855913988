import React from 'react'
import './logo.scss'
const LOGO = require('../../../../assets/images/BC_Logo_desktop.png')

export const Logo = () => {

    return(
        <div className="logo-wrap">
            <img className="logo" src={LOGO} alt="logo"/>
        </div>
    )
}