/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import "./exemple-video.scss";
import { dataSlider, dataButton } from "./dataForSlider";
import { BsCaretLeft, BsCaretRight } from "react-icons/bs";
import { Element } from "react-scroll";

export const ExempleVideo = () => {
  const slider = useRef();
  const player = useRef();
  const [isIndex, setIndex] = useState(0);
  const [sizeArrow, setSizeArrow] = useState(56);

  useEffect(() => {
    const width = window.innerWidth;
    if (width < 640) {
      setSizeArrow(36);
    }
  }, []);

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <button className="video__btnNext" onClick={onClick}>
        <BsCaretRight size={sizeArrow} color="#2ba3f1" />
      </button>
    );
  };
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button className="video__btnPrev" onClick={onClick}>
        <BsCaretLeft size={sizeArrow} color="#2ba3f1" />
      </button>
    );
  };
  const setting_btn = {
    speed: 500,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          nav: true,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          nav: true,
        },
      },
    ],
  };

  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const sliderItem = dataSlider[isIndex].map((item, index) => {
    return (
      <div className="video__slide" key={item.id}>
        <h3 className="video__head">{item.title}</h3>
        <p className="video__categories" style={{ color: item.color }}>
          <img className="video__iconCat" src={item.icon} alt="icon" />
          {item.categories}
        </p>
        <ReactPlayer
          ref={player}
          controls={true}
          playsinline={true}
          className="box-player"
          width="100%"
          height="100%"
          url={item.url}
        />
        <p className="video__description">
          <b className="video__innerDescription">Описание: </b>
          {item.description}
        </p>
      </div>
    );
  });

  const hendlerMonthSlider = (index) => {
    slider.current.slickGoTo(index);
    if (dataSlider.length > isIndex) {
      setIndex(index);
    } else {
      setIndex(0);
    }
  };

  const buttonItem = dataButton.map((item, index) => {
    return (
      <div className="video__btnWrap" key={item.id}>
        <button
          className="video__btn"
          onClick={() => hendlerMonthSlider(index)}
        >
          {item.text}
        </button>
      </div>
    );
  });
  return (
    <Element className="video" name="video">
      <div className="video-wrapper">
        <div className="container">
          <h2 className="title-h2">Примеры видео упражнений</h2>
          <div className="video__content">
            <div className="video__buttons">
              <Slider ref={slider} {...setting_btn}>
                {buttonItem}
              </Slider>
            </div>
            <div className="video__sliders">
              <Slider {...settings}>{sliderItem}</Slider>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};
