import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { action_request } from '../../../redux/actions/action_request'
import './request_form.scss'
import { useHttp } from '../hooks/http.hook'
import { config } from '../../ets-scripts/config'
import CustomForm from './CustomForm'

export const RequestForm = (props) => {
  const { request, isError } = useHttp()
  const [isEr, setEr] = useState(isError)
  const [isSeccess, setSeccess] = useState(false)
  const { request_form } = useSelector((state) => state)
  const dispatch = useDispatch()

  const handlerSubmit = async (values) => {
    const addFlag = { ...values, flag: props.data.flag }

    const data = await request(
      `${config.URL}/mail/guide`,
      'POST',
      JSON.stringify(addFlag),
      { 'Content-Type': 'application/json' }
    )

    if (data.status === 200) {
      setSeccess(true)
      setEr('Мы приняли вашу заявку в работу.')
      dispatch(action_request({ ...request_form, email: '', phone: '', name: '' }))
    } else {
      setEr(data.message)
      dispatch(action_request({ ...request_form, email: '', phone: '', name: '' }))
    }
  }
  return (
    <div className="form-request">
      <div className="container">
        <h2 className="title-h2">{props.data.text}</h2>
        <div className="form-request__content">
          {!isSeccess ? (
            <div className="form-request__form">
              <CustomForm handlerSubmit={handlerSubmit}/>
            </div>
          ) : (
            <div className="form-request__success">
              <svg
                className="ft-green-tick"
                xmlns="http://www.w3.org/2000/svg"
                height="100"
                width="100"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <circle
                  className="circle"
                  fill="#2ba3f1"
                  cx="24"
                  cy="24"
                  r="22"
                />
                <path
                  className="tick"
                  fill="none"
                  stroke="#FFF"
                  strokeWidth="6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M14 27l5.917 4.917L34 17"
                />
              </svg>
              <p className="form-request__successText">
                Благодарим Вас за Вашу заявку. Как только приложение будет
                доступно, мы сразу оповестим Вас и предоставим доступ.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
