import React, { useEffect, useRef, useState } from 'react'
import './about_app_home.scss'
import Slider from 'react-slick'
import { dataSlider, promo_video } from './data-for-slider'
import { Element, Link } from 'react-scroll'
import ReactPlayer from 'react-player'

const settings = {
  fade: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 4000,
      },
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

export const AboutAppHome = () => {
  const player = useRef()
  const [isExpand, setExpand] = useState(false)
  const dataForCard = dataSlider
  const [counter, setCounter] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setCounter(!counter)
    }, 3000)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter])

  useEffect(() => {
    const width = window.innerWidth
    if (width < 640) {
      setExpand(true)
    }
  }, [])

  const itemCard = dataForCard.map((item, index) => {
    return (
      <div className="about-app__col" key={index}>
        <div className="about-app-card">
          <picture>
            <source
              className="about-app-card__img"
              srcSet={item.img2000}
              type="image/jpf"
            />
            <img className="about-app-card__img" srcSet={item.img} alt=""/>
          </picture>
          <div className="about-app-card__body">
            <p
              className={
                counter
                  ? 'about-app-card__text about-app-card__text-active'
                  : 'about-app-card__text'
              }
            >
              {item.text}
            </p>
            <p
              className={
                counter
                  ? 'about-app-card__text'
                  : 'about-app-card__text about-app-card__text-active'
              }
            >
              {item.textAfter}
            </p>
          </div>
        </div>
      </div>
    )
  })

  return (
    <Element className="about-app" name="about-app">
      <div className="about-app__header">
        <h2 className="title-h2">
          <span className="title-h2__innertitle">Baby Course </span> - это
          программа развития Вашего ребёнка с 0 до 3 лет в мобильном приложении
        </h2>
      </div>
      <div className="about-app__video">
        <ReactPlayer
          ref={player}
          controls={true}
          playsinline={true}
          className="box-player"
          width="100%"
          height="100%"
          url={promo_video.uri}
        />
      </div>
      <div className="about-app__header">
        <h2 className="title-h2">
          Все необходимое для развития Вашего ребёнка в одном приложении:
        </h2>
      </div>
      {isExpand ? (
        <>
          <Slider {...settings}>{itemCard}</Slider>
          <button
            className="about-app__showbtn"
            style={{ position: 'absolute', left: 0, right: 0 }}
            onClick={() => setExpand(false)}
          >
            Все интерфейсы
          </button>
        </>
      ) : (
        <>
          <div className="about-app__content">{itemCard}</div>
          <Link
            activeClass="active"
            className="about-app__showbtn"
            to="about-app"
            spy={true}
            smooth={true}
            duration={500}
            onClick={() => setExpand(true)}
          >
            Свернуть
          </Link>
        </>
      )}
    </Element>
  )
}
